import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 className='pageTitle'>EXILETECH</h1>
        <p className='subTitleOne'>under construction</p>
        <p className='subTitleTwo'>contact: support@exiletech.org</p>
      </header>
      <p className='copyrightTitle'>copyright © 2022 | exiletech.org</p>
    </div>
  );
}

export default App;
